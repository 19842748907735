//$(document).ready(function () {

//    if (is_touch_device()) {
//        //$('#container').prepend('<div id="nav-btn"></div>');
//        $(".xloc-mobile-menus").show();
//        $(".xloc-subheader-desktop").hide();
//        $(".xloc-language-subheader-desktop").hide();
//    }
//});

function is_touch_device() {
    return 'ontouchstart' in window        // works on most browsers 
        || navigator.maxTouchPoints;       // works on IE10/11 and Surface
};

function init_mobile_header_menus() {

    $(document).on("click", ".icon-dd-indicator", function () {
        var link = $(this).closest("li").find("a").attr("href");
        window.location.href = link;
    });

    $(document).on("click", ".xloc-mobile-menu-trigger", function () {
        if ($(this).hasClass("open")) {
            $(this).removeClass("open");
        } else {
            $(".xloc-mobile-menu-trigger").addClass("open");
        }

        $(".xloc-mobile-menus").stop().slideToggle();
    });

    //$(document).on("click", ".DGHead, .DGNormal, .DGAlt, .DGEdit, .ColorPriorityLow, .ColorPriorityMedium, .ColorPriorityHigh, .ColorPriorityUrgent", function () {
    //    if ($(this).hasClass("expanded")) {
    //        $(this).removeClass("expanded");
    //    } else {
    //        $(this).addClass("expanded");
    //    }
    //});

    $(document).on("click", ".table-extend-btn", function () {
        if ($(this).hasClass("extended")) {
            $(this).removeClass("extended");
            $(this).text("Expand All Rows");
            $(".DGHead, .DGNormal, .DGAlt, .DGEdit, .ColorPriorityLow, .ColorPriorityMedium, .ColorPriorityHigh, .ColorPriorityUrgent, .ColorPriorityBlue, .ColorPriorityBrown, .ColorPriorityGrey, .ColorPriorityLime, .ColorPriorityMagenta, .ColorPriorityOrange, .ColorPriorityPurple, .ColorPriorityWhite, .ColorPriorityNormal, .DGInactive").removeClass("expanded");
        } else {
            $(this).addClass("extended");
            $(this).text("Collapse All Rows");
            $(".DGHead, .DGNormal, .DGAlt, .DGEdit, .ColorPriorityLow, .ColorPriorityMedium, .ColorPriorityHigh, .ColorPriorityUrgent, .ColorPriorityBlue, .ColorPriorityBrown, .ColorPriorityGrey, .ColorPriorityLime, .ColorPriorityMagenta, .ColorPriorityOrange, .ColorPriorityPurple, .ColorPriorityWhite, .ColorPriorityNormal, .DGInactive").addClass("expanded");
        }
    });

    $(document).on("click", ".xloc-mobile-menus > .column", function () {

        var menu = $(this).find("> ul");

        if ($(this).hasClass("open")) {
            // menu is open and all menus should close
            console.log('menu currently open');
            $(this).removeClass("open");
            menu.stop().slideUp(100);
            return;
        }

        var open_count = $('.xloc-mobile-menus .column.open').length;

        if (open_count == 0) {

            console.log('no other menus open');

            $(this).find("> ul").stop().slideDown();
            $(this).addClass("open");

            return;
        }

        console.log('different menu open');

        $(".xloc-mobile-menus > .column").removeClass("open");
        $(this).addClass("open");
        // a different menu is open, needs to be closed and the new menu needs to be open
        $(".xloc-mobile-menus > .column").find("> ul").slideUp(100, function () {
            menu.stop().slideDown();
        });

    })
}

function hide_element_if_child_not_exist() {
    $(".hide-elelment-without-children").each(function () {
        var requiredChildElementsClass = $(this).data("required-child-class");

        var requiredChildElemetnt = $(this).find("." + requiredChildElementsClass)

        if (requiredChildElemetnt.length == 0)
            $(this).hide();
        else
            $(this).show();
    });
}

function init_actions() {

    $(document).on('click', '.additional-trigger', function () {

        var triggerItemId = $(this).data('item-class');

        $('.' + triggerItemId).simulateClick('click');

    });

    $(document).on('click', '.search-button', function (event) {
        var target = $('.table-module');
        if (target.length) {
            event.preventDefault();
            $('html, body').stop().animate({
                scrollTop: target.offset().top
            }, 1000);
        }
    });
}

function show_search_tables() {

    $(".table-wrapper, .sql-wrapper").hide();
    $(".table-wrapper, .sql-wrapper").show();
}

function sweetAlertConfirm(btnDelete, title, text, buttonText) {
    if (btnDelete.dataset.confirmed) {
        // The action was already confirmed by the user, proceed with server event
        btnDelete.dataset.confirmed = false;
        return true;
    } else {
        // Ask the user to confirm/cancel the action
        event.preventDefault();
        swal({
            title: title,
            text: text,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: buttonText
        })
        .then(function () {
            // Set data-confirmed attribute to indicate that the action was confirmed
            btnDelete.dataset.confirmed = true;
            // Trigger button click programmatically
            btnDelete.click();
        }).catch(function (reason) {
            // The action was canceled by the user
        });
    }
}

function extend(a, b) {
    for (var key in b)
        if (b.hasOwnProperty(key))
            a[key] = b[key];
    return a;
}

// keep default js alert to use in specific cases
window.legacyAlert = window.alert;

// types alert and confirm: "success", "error", "warning", "info", "question". Default: "warning"
// overwrite default js alert
window.alert = function (msg, title, type, params) {
    var title = (title == null) ? 'Warning!' : title;
    var type = (type == null) ? 'warning' : type;
    swal($.extend({
        title: title,
        text: msg,
        type: type
    }, params || {})
    );
};

jQuery.fn.simulateClick = function () {
    return this.each(function () {
        if ('createEvent' in document) {
            var doc = this.ownerDocument,
                evt = doc.createEvent('MouseEvents');
            evt.initMouseEvent('click', true, true, doc.defaultView, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
            this.dispatchEvent(evt);
        } else {
            this.click(); // IE Boss!
        }
    });
}

$(document).ready(function () {

    $(document).foundation();

    $('body').change(function () {
        hide_element_if_child_not_exist();
    });

    hide_element_if_child_not_exist();
});

init_mobile_header_menus();
init_actions();